import { showError } from './utils';
import axios from 'axios';

export const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER ? process.env.REACT_APP_SERVER : '',
  withCredentials: true, // 如果你需要跨域请求时发送cookies
});

API.interceptors.response.use(
  (response) => {
    // 检查是否有set-cookie头，并提取cookie
    const cookies = response.headers['set-cookie'];
    if (cookies) {
      cookies.forEach(cookie => {
        // 这里可以根据实际cookie格式进行解析
        const [name, value] = cookie.split(';')[0].split('=');
        // 这里可以将解析出来的cookie存储在本地，例如localStorage
        localStorage.setItem(name, value);
      });
    }
    return response;
  },
  (error) => {
    showError(error);
  }
);
